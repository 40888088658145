require('./bootstrap');

$(document).ready(() => {

    // Deal with mandatory checkboxes

    var groupsCheckboxes = {};
    var checkAtLeastOneIsChecked = function(){
        var name = $(this).attr('name').replace(/\[\s?\]$/, '');
        if(groupsCheckboxes.hasOwnProperty(name)){
            var inputs = groupsCheckboxes[name];
            var atLeastOneIsCheck = false;
            $(inputs).each(function(){
                if($(this).is(':checked')){
                    atLeastOneIsCheck = true;
                }
            });
            if(atLeastOneIsCheck) {
                $(inputs).each(function(){ $(this).prop('required', false)});
            } else {
                $(inputs).each(function(){ $(this).prop('required', true)});
            }
        }
    }
    $("input[type=checkbox][name*=\\[\\]][required], input[type=radio][name*=\\[\\]][required]").each(function(){
        var name = $(this).attr('name').replace(/\[\s?\]$/, '');
        if(!groupsCheckboxes.hasOwnProperty(name)) groupsCheckboxes[name] = [];
        $(this).change(checkAtLeastOneIsChecked);
        groupsCheckboxes[name].push(this);
    });

    //

    $('a[data-webinar]').click(function(){
        var room = $(this).data('room');
        var webinar = $(this).data('webinar');

        openFormModal(room, webinar);
    });

    $('a[data-room]').click(function(){
        var room = $(this).data('room');
        openFormModal(room);
    });

    $('a[data-modal]').click(function(){

        var modal = $('#' + $(this).data('modal'));
        modal.fadeIn('100');

        return false;
    });

    $('.classes-teaching').select2();
    $('.years-teaching').select2();
    $('.method-teaching').select2();

    var splitWebinarNames = function(state){

        if(state.text.indexOf(' - ') > -1) {
            var texts = state.text.split(' - ');
            var datetime = texts.shift();
            return texts.join(' - ');
        }

        return state.text;
    }

    $('select.multiple').each(function(){
        $(this).select2({
            placeholder: $(this).data('placeholder'),
            language: "fr",
            allowClear: true,
            width: '90%',
            templateSelection: splitWebinarNames
        });
    });

    $('#choose-school-postal').keyup( function(){

        var value = $(this).val();
        if(value.length >= 4) {

            var callSchool = function() {
                $.ajax({
                   url : '/api/schools/' + value,
                   type : 'GET',
                   dataType : 'json',
                   success : function(result, status){

                      $('.school-selector').removeClass('hidden')
                      $('.school-container select').remove();

                      $select = $('<select name="school_id" />');
                      for(var i in result) {
                          $select.append('<option value="' + i + '" >' + result[i] + '</option>');
                      }

                      if(result.length == 0) {
                          $select.append('<option value="0" >Aucune école trouvée pour ce code postal</option>');
                      }

                      $('.school-container').append($select);
                   }
                });
            }

            var throttle = setTimeout(function(){
                clearTimeout(throttle);
                callSchool();
            }, 1000);
        }
    });

    $('#teaching_courses').change(function(){
        var courses = $(this).val();
        $.ajax({
               url : '/api/courses',
               type : 'POST',
               data: { courses : courses },
               dataType : 'json',
               success : function(result, status){

                   $('#teaching_methods option:not(:selected)').remove();
                   $('#teaching_methods optgroup').each(function(){
                       if($(this).find('option').length <= 0){
                           $(this).remove();
                       }
                   });

                   var hasResult = false;
                   for(var i in result) {
                       hasResult = true;

                       var options = result[i];
                       var label = i.replace(/"/g,'\"');

                       if($('#teaching_methods optgroup[label="'+ label +'"]').length == 0 ) {

                           var group = '<optgroup label="' + label + '" >';
                           for(var o in options){
                               group += '<option value="'+ options[o].replace(/"/g,'\"') +'" >'+ options[o] +'</option>';
                           }
                           group += '</optgroup>'

                           $('#teaching_methods').append(group).trigger('change');

                       }
                   }

                   if(hasResult) {
                       $('#teaching_methods').parents('.select-container').show();
                   } else {
                       $('#teaching_methods').parents('.select-container').hide();
                   }
               }
        });

    });

    $('.choice--webinar input[type=checkbox]').change( function(){
        var values = [];
        $('.choice--webinar input[type=checkbox]:checked').each(function(){
            values.push($(this).val());
        });
        $.ajax({
           url : '/api/webinars/' + (values.length > 0 ? values.join(',') : 0),
           type : 'GET',
           dataType : 'json',
           success : function(result, status){

              var webinar = $( ".modal_form" ).data('webinar');

              $('.choice-courses-container').removeClass('hidden');

              $('#choice-courses option:not(:selected)').remove();

              if(webinar) {
                  $('#choice-courses option[value=' + webinar + ']').remove();
              }

              $('#choice-courses option').trigger('change');

              for(var i in result) {
                  var course = result[i];

                  //var label = course.date + ' à ' + course.time + ' - ' + course.title;
                  var label = course.title;
                  var newOption = new Option(label, course.id, false, false);
                  $('#choice-courses').append(newOption).trigger('change');
              }

              if(webinar) {
                  if($("#choice-courses").val().indexOf(String(webinar)) <= -1) {
                      $("#choice-courses").val(webinar);
                  }
                  $("#choice-courses").trigger('change');
              }
           }
        });
    });

    var openFormModal = function(room = false, webinar = false) {

        $( ".modal_form" ).show();
        $( "body" ).addClass( "fixed" );

        $( ".modal_form" ).removeData();

        if(room) {
            $( ".modal_form" ).data('room', room);
            if($('.choice--webinar input[type=checkbox], .choice--webinar input[type=radio]').length > 0) {
               $('.choice--webinar input[type=checkbox], .choice--webinar input[type=radio]').prop( "checked", false );
               $('.choice--webinar input[type=checkbox][value=' + room + '], .choice--webinar input[type=radio][value=' + room + ']').prop( "checked", true ).trigger('change');
            }
        }

        if(webinar) {
            $("#choice-courses").val([]);
            $( ".modal_form" ).data('webinar', webinar);
            if($("#choice-courses").val().indexOf(String(webinar)) <= -1) {
                $("#choice-courses").val(webinar);
            }
            $("#choice-courses").trigger('change');
        }

        return $( ".modal_form" );
    }

    if(window.location.search.indexOf('reconnect') > -1) {
        openFormModal();
    }

    $('#contact_form').submit(function(){
        //$('.loader-container').removeClass('hidden');
        //$(this).find('button').attr("disabled", true);
        return true;
    });


    $('#init_form').submit(function(){

        // Copy field to modal form
        $(this).find('input').each(function(){
            var inputName = $(this).attr('name');
            if(inputName.length > 0 ) {
                $('#contact_form').find('input[name=' + inputName + ']').val($(this).val());
            }
        });

        // If email is not logged in
        if($('body[data-login]').length == 0 ) {

            // Check if email exists and reconnect
            $.post('/api/reconnect', {'email': $(this).find('input[name=email]').val() },
                function(response){

                    if(!response.hasOwnProperty('connected') || !response.connected)
                    {
                        openFormModal();
                    } else {
                        var url = window.location.href;
                        url += (url.indexOf('?') > -1) ? '&' : '?';
                        window.location.href = url + 'reconnect';
                        //openFormModal();
                    }

                }, 'json');

        } else {

            openFormModal();
        }

        return false;
    });

    // (function () {
    //     const second = 1000,
    //         minute = second * 60,
    //         hour = minute * 60,
    //         day = hour * 24;

    //     // let birthday = "March 24, 2021 14:00:00",
    //         let birthday = "March 16, 2021 11:00:00",
    //         countDown = new Date(birthday).getTime(),
    //         x = setInterval(function() {

    //           let now = new Date().getTime(),
    //               distance = countDown - now;

    //                 document.getElementById("days").innerText = Math.floor(distance / (day)),
    //                 document.getElementById("hours").innerText = Math.floor((distance % (day)) / (hour)),
    //                 document.getElementById("minutes").innerText = Math.floor((distance % (hour)) / (minute)),
    //                 document.getElementById("seconds").innerText = Math.floor((distance % (minute)) / second);
    //         //do something later when date is reached
    //         if (distance < 0) {
    //             $(".countdown").addClass('countdown_end');
    //             $(".online_event--intro").addClass('online_open');
    //             $(".subtmit--event_open").addClass('online_open');


    //             clearInterval(x);
    //         }

    //     }, 0)
    // }());

    $( ".add-school" ).click(function() {
        $( ".ajout-ecole" ).show( "slow" );
    });

    // $( ".content-video iframe" ).click(function() {
    //     $(this).addClass('test');
    // });
    $(function(){
        $('#videoPlay').click(function(){
            $(this).addClass('videoPlay-active');

            $("#video")[0].src += "&autoplay=1";
            ev.preventDefault();

        });
    });
    /*$( ".modal_close" ).click(function() {
        $( ".modal_form" ).hide( "slow" );
        $( "body" ).removeClass( "fixed" );
    });*/

    $('.modal .modal_close').click(function(){
        $(this).parents('.modal').hide();
        $( "body" ).removeClass( "fixed" );
    });

    $( ".btn_subscribe" ).click(function() {
        // Moved in form submit ^^
        //$( ".modal_form" ).show( "slow" );
    });

    AOS.init();

/* TABS */

jQuery('ul.tabs').each(function(){

    var $active, $content, $links = jQuery(this).find('a');


    $active = jQuery($links.filter('[href="'+location.hash+'"]')[0] || $links[0]);
    $active.addClass('active');

    $content = $($active[0].hash);

    $links.not($active).each(function () {
        jQuery(this.hash).hide();
    });

    jQuery(this).on('click', 'a', function(e){

        $active.removeClass('active');
        $content.hide();


        $active = jQuery(this);
        $content = jQuery(this.hash);

        // Make the tab active.
        $active.addClass('active');
        $content.show();

        // Prevent the anchor's default click action
        e.preventDefault();
    });
});

// (function () {
//     const second = 1000,
//         minute = second * 60,
//         hour = minute * 60,
//         day = hour * 24;

//     let birthday = "March 24, 2021 14:00:00",
//         countDown = new Date(birthday).getTime(),
//         x = setInterval(function() {

//           let now = new Date().getTime(),
//               distance = countDown - now;

//                 document.getElementById("days-programme").innerText = Math.floor(distance / (day)),
//                 document.getElementById("hours-programme").innerText = Math.floor((distance % (day)) / (hour)),
//                 document.getElementById("minutes-programme").innerText = Math.floor((distance % (hour)) / (minute)),
//                 document.getElementById("seconds-programme").innerText = Math.floor((distance % (minute)) / second);

//           //seconds
//     }, 0)
// }());

var swiper = new Swiper('.swiper-container', {
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },

       breakpoints: {
        640: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
      },
  });




})

