window.$ = window.jQuery = require('jquery');

require('select2');
//require('select2/dist/js/i18n/fr.js');

! function() {
    if (jQuery && jQuery.fn && jQuery.fn.select2 && jQuery.fn.select2.amd) var e = jQuery.fn.select2.amd;
    e.define("select2/i18n/fr", [], function() {
        return {
            errorLoading: function() {
                return "Les résultats ne peuvent pas être chargés."
            },
            inputTooLong: function(e) {
                var n = e.input.length - e.maximum;
                return "Supprimez " + n + " caractère" + (n > 1 ? "s" : "")
            },
            inputTooShort: function(e) {
                var n = e.minimum - e.input.length;
                return "Saisissez au moins " + n + " caractère" + (n > 1 ? "s" : "")
            },
            loadingMore: function() {
                return "Chargement de résultats supplémentaires…"
            },
            maximumSelected: function(e) {
                return "Vous pouvez seulement sélectionner " + e.maximum + " élément" + (e.maximum > 1 ? "s" : "")
            },
            noResults: function() {
                return "Sélectionnez au moins une salle ci-dessus"
            },
            searching: function() {
                return "Recherche en cours…"
            },
            removeAllItems: function() {
                return "Supprimer tous les éléments"
            },
            removeItem: function() {
                return "Supprimer l'élément"
            }
        }
    }), e.define, e.require
}();

window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });
